import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ShipSearchResult, ShipUuid } from 'src/types';
import * as fromShips from '../actions/ship.actions';
import { ShipService } from '../services/ship.service';

@Injectable({ providedIn: 'root' })
export class ShipEffects {
  constructor(
    private actions$: Actions,
    private shipService: ShipService,
  ) {}

  loadShips$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromShips.loadShips),
      switchMap(() =>
        this.shipService.loadShips().pipe(
          map(({ data: ships }) => fromShips.loadShipsSuccess({ ships })),
          catchError((error) => of(fromShips.loadShipsFailure({ error }))),
        ),
      ),
    );
  });

  loadShipUuids$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromShips.loadShipUuids, fromShips.createLinkSuccess),
      switchMap(() =>
        this.shipService.loadShipUuids().pipe(
          map(({ data: shipUuids }) => fromShips.loadShipUuidsSuccess({ shipUuids: shipUuids as ShipUuid[] })),
          catchError((error) => of(fromShips.loadShipUuidsFailure({ error }))),
        ),
      ),
    );
  });

  createLink$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromShips.createLink),
      switchMap(({ imo, chartererReference }) =>
        this.shipService.createLink(imo, chartererReference).pipe(
          map((response) => fromShips.createLinkSuccess({ imo, response })),
          catchError((error) => of(fromShips.createLinkFailure({ error }))),
        ),
      ),
    );
  });

  loadLink$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromShips.loadLink),
      switchMap(({ uuid }) =>
        this.shipService.getShipUuid(uuid).pipe(
          map(({ data: [shipUuid] }) => fromShips.loadLinkSuccess({ shipUuid })),
          catchError((error) => of(fromShips.getLinkFailure({ error }))),
        ),
      ),
    );
  });

  loadLinkWithImo = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromShips.loadLinkFromImo),
      switchMap(({ imo, cpid }) =>
        this.shipService.getShipUuidWithImo(imo).pipe(
          map(({ data: shipUuids }) => {
            if (!shipUuids || shipUuids?.length === 0) {
              return fromShips.getLinkFailure({ error: `${imo} has no ship uuids` });
            }
            const shipUuid = shipUuids.find(({ chartererReference }) => chartererReference === cpid) || shipUuids.pop();

            return shipUuid
              ? fromShips.loadLinkSuccess({ shipUuid })
              : fromShips.getLinkFailure({ error: `${imo} has no ship uuids` });
          }),
          catchError((error) => of(fromShips.getLinkFailure({ error }))),
        ),
      ),
    );
  });

  getShip$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromShips.getShip),
      switchMap(({ imo }) =>
        this.shipService.getShip(imo).pipe(
          map(({ data: [ship] }) => fromShips.getShipSuccess({ ship })),
          catchError((error) => of(fromShips.getLinkFailure({ error }))),
        ),
      ),
    );
  });

  searchShips$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromShips.searchShips),
      switchMap(({ term }) =>
        this.shipService.searchShips(term).pipe(
          map((response) => {
            const ships: ShipSearchResult[] = response.map(({ imo, name, similarity }) => ({
              imo,
              name,
              similarity,
            }));
            return fromShips.searchShipsSuccess({ ships });
          }),
          catchError((error) => of(fromShips.searchShipsFailure({ error }))),
        ),
      ),
    );
  });
}
