import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SiglarUser } from 'src/types';

@Injectable({ providedIn: 'root' })
export class UserService {
  siglarApiUrl = environment.siglarApi.url;

  constructor(private http: HttpClient) {}

  loadUsers() {
    const { href: userEndpointUrl } = new URL('/api/v1/users', this.siglarApiUrl);
    return this.http.get<{ body: { complete: SiglarUser[] } }>(userEndpointUrl, { observe: 'body' });
  }

  loadOpsUsers() {
    const { href: userEndpointUrl } = new URL('/api/v1/users/operations/all', this.siglarApiUrl);
    return this.http.get<{ body: { complete: SiglarUser[] } }>(userEndpointUrl, { observe: 'body' });
  }

  createUser(user: any) {
    const createUserEndpointUrl = new URL('/api/v1/users', this.siglarApiUrl);
    return this.http.post<{ status: string; uuid: string }>(createUserEndpointUrl.href, user);
  }

  updateUser(user: any) {
    const updateUserEndpointUrl = new URL(`/api/v1/users/${user.email}`, this.siglarApiUrl);
    return this.http.put<SiglarUser>(updateUserEndpointUrl.href, user);
  }

  deleteUser(email: string) {
    const deleteUserEndpointUrl = new URL(`/api/v1/users/${email}`, this.siglarApiUrl);
    return this.http.delete<{ deleted: boolean }>(deleteUserEndpointUrl.href);
  }
}
