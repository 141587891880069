import { Action, createReducer, on } from '@ngrx/store';
import {
  VoyageAnomalies,
  FrontendVoyage,
  Voyage,
  VoyageAnalysis,
  VoyageAnalysisCompact,
  VoyageComment,
  VoyageFilters,
  VoyageListItem,
  VoyageStatus,
  VoyageSummary,
} from 'src/types';
import { deleteMultipleReportsSuccess, deleteReportSuccess, updateReportSuccess } from '../actions/report.actions';
import {
  addToTagFilter,
  addVoyageCommentSuccess,
  loadVoyageAnomalies,
  loadVoyageAnomaliesSuccess,
  updateVoyageCommentSuccess,
  clearSearchFilter,
  deleteVoyageCommentSuccess,
  deleteVoyageSuccess,
  loadShipVoyages,
  loadShipVoyagesFailure,
  loadShipVoyagesSuccess,
  loadVoyageCommentsFailure,
  loadVoyageCommentsSuccess,
  clearVoyageComments,
  loadVoyageDetails,
  loadVoyageDetailsFailure,
  loadVoyageDetailsSuccess,
  loadVoyagesList,
  loadVoyagesListFailure,
  loadVoyagesListSuccess,
  patchVoyageSuccess,
  resetVoyageReportRangeSuccess,
  removeFromTagFilter,
  resetTagFilter,
  setCheckedVoyageUuids,
  setCompanyFilter,
  setSearchFilter,
  setViewFilter,
  toggleCheckVoyageUuid,
  unverifyVoyageSuccess,
  updateVoyageSuccess,
  verifyVoyageSuccess,
  patchPreferredVoyageDistanceSuccess,
  loadVoyageSummarySuccess,
  loadVoyageSummaryFailure,
  verifyVoyage,
  verifyVoyageFailure,
  assignVoyageSuccess,
} from '../actions/voyage.actions';
export interface State {
  entries: VoyageAnalysisCompact[];
  voyageList: VoyageListItem[];
  shipVoyages: Voyage[];
  verifiedEntries: FrontendVoyage[];
  currentVoyageDetails: VoyageAnalysis;
  currentVoyageComments: VoyageComment[];
  anomalies: VoyageAnomalies[];
  checkedVoyageUuids: string[];
  currentQuerySize: number;
  voyageSummary: VoyageSummary;
  filters: Partial<VoyageFilters>;
  error: string;
  searchFilter: string;
  filterTags: string[];
  filter: VoyageStatus;
  filterByCompanyId: string | null;
  loading: boolean;
}

export const initalState: State = {
  entries: [],
  voyageList: [],
  verifiedEntries: [],
  shipVoyages: [],
  currentVoyageDetails: null,
  anomalies: [],
  currentVoyageComments: [],
  checkedVoyageUuids: [],
  currentQuerySize: 0,
  voyageSummary: {
    companies: [],
    voyages: null,
  },
  filters: null,
  error: '',
  searchFilter: '',
  filterTags: [],
  filter: 'allVoyages',
  filterByCompanyId: null,
  loading: false,
};

export const reducerFn = createReducer(
  initalState,
  on(loadShipVoyages, loadVoyageDetails, loadVoyageAnomalies, loadVoyagesList, verifyVoyage, (state) => ({
    ...state,
    loading: true,
  })),
  on(loadVoyageAnomaliesSuccess, (state, { anomalies }) => ({
    ...state,
    anomalies: anomalies,
    loading: false,
  })),
  on(
    loadVoyageDetailsFailure,
    loadVoyagesListFailure,
    loadShipVoyagesFailure,
    loadVoyageSummaryFailure,
    verifyVoyageFailure,
    (state, { error }) => ({
      ...state,
      error,
      loading: false,
    })
  ),
  on(loadVoyagesListSuccess, (state, { voyages: voyageList, count }) => {
    const transformedVoyages = voyageList.map((voyage) => {
      const { voyage: nestedVoyage, ...rest } = voyage;
      const {
        charter_name: chartererName,
        num_comments: numComments,
        last_comment: lastComment,
        start_report: startReport,
        last_report: lastReport,
        ship_name: shipName,
        ...otherFields
      } = rest;
      return {
        ...nestedVoyage,
        chartererName,
        numComments,
        lastComment,
        shipName,
        startReport,
        lastReport,
        ...otherFields,
      };
    });
    return {
      ...state,
      voyageList: transformedVoyages,
      loading: false,
      currentQuerySize: count
    };
  }),
  on(loadShipVoyagesSuccess, (state, { shipVoyages }) => ({
    ...state,
    shipVoyages,
    loading: false,
  })),
  on(loadVoyageSummarySuccess, (state, { voyageSummary }) => ({
    ...state,
    voyageSummary,
    loading: false,
  })),
  on(loadVoyageDetailsSuccess, (state, { voyage: currentVoyageDetails }) => ({
    ...state,
    currentVoyageDetails,
    loading: false,
  })),
  on(loadVoyageDetailsFailure, (state) => ({
    ...state,
    currentVoyageDetails: null,
    loading: false,
  })),
  on(loadVoyageCommentsSuccess, (state, { comments }) => {
    // Remove pinned comment from the list
    const currentVoyageComments = comments.filter((comment) => !comment.pinned);
    // Get and add pinned comment if it exitsts
    const pinnedComment = comments.find((comment) => comment.pinned);
    if (pinnedComment) {
      currentVoyageComments.push(pinnedComment);
    }

    return {
      ...state,
      currentVoyageComments,
      loading: false,
    };
  }),
  on(loadVoyageCommentsFailure, (state) => ({
    ...state,
    currentVoyageComments: [],
    loading: false,
  })),
  on(clearVoyageComments, (state) => ({
    ...state,
    currentVoyageComments: [],
  })),
  on(addVoyageCommentSuccess, (state, { comment: newComment }) => {
    // Remove pinned comment from the list
    const currentVoyageComments = [...state.currentVoyageComments, newComment].filter((comment) => !comment.pinned);
    // Get and add pinned comment if it exitsts
    const pinnedComment = currentVoyageComments.find((comment) => comment.pinned);
    if (pinnedComment) {
      currentVoyageComments.push(pinnedComment);
    }

    return {
      ...state,
      currentVoyageComments,
      loading: false,
    };
  }),
  on(deleteVoyageSuccess, (state, { uuid: deleteUuid }) => {
    const checkedVoyageUuids = [...state.checkedVoyageUuids];
    const idx = checkedVoyageUuids.findIndex((existingUuid) => existingUuid === deleteUuid);
    checkedVoyageUuids.splice(idx, 1);
    return {
      ...state,
      entries: state.entries.filter(({ voyage: { uuid } }) => uuid !== deleteUuid),
      checkedVoyageUuids,
      loading: false,
    };
  }),
  on(updateVoyageCommentSuccess, (state, { comment: editedComment }) => {
    const { currentVoyageComments: comments } = state;
    let currentVoyageComments: VoyageComment[] = [];
    const otherComments = comments.filter((comment) => comment.id !== editedComment.id);
    const pinnedComment = otherComments.find((comment) => comment.pinned);

    if (pinnedComment && !editedComment.pinned) {
      currentVoyageComments = [...otherComments, editedComment].filter((comment) => !comment.pinned);
      currentVoyageComments.push(pinnedComment);
    } else {
      currentVoyageComments = otherComments.map((comment) => ({ ...comment, pinned: false }));
      currentVoyageComments.push(editedComment);
    }

    return {
      ...state,
      currentVoyageComments,
      loading: false,
    };
  }),
  on(deleteVoyageCommentSuccess, (state, { commentId }) => {
    const clonedComments = [...state.currentVoyageComments];
    const currentVoyageComments = clonedComments.filter(({ id }) => id !== commentId);
    return {
      ...state,
      currentVoyageComments,
      loading: false,
    };
  }),
  on(
    patchVoyageSuccess,
    updateVoyageSuccess,
    verifyVoyageSuccess,
    patchPreferredVoyageDistanceSuccess,
    resetVoyageReportRangeSuccess,
    (state, { voyage }) => {
      const {
        entries: previousEntries,
        currentVoyageDetails: previousCurrentVoyageDetails,
        checkedVoyageUuids: previousCheckedVoyageUuids,
      } = state;

      const entries = previousEntries.map((voyageAnalysis) =>
        voyageAnalysis.voyage.uuid === voyage.uuid ? { ...voyageAnalysis, voyage } : voyageAnalysis
      );

      const currentVoyageDetails =
        previousCurrentVoyageDetails?.voyage?.uuid === voyage.uuid
          ? { ...previousCurrentVoyageDetails, voyage }
          : previousCurrentVoyageDetails;

      const checkedVoyageUuids = previousCheckedVoyageUuids.filter((uuid) => uuid !== voyage.uuid);

      return {
        ...state,
        entries,
        currentVoyageDetails,
        checkedVoyageUuids,
        loading: false,
      };
    }
  ),
  on(setSearchFilter, (state, { searchValue: searchFilter }) => ({ ...state, searchFilter })),
  on(clearSearchFilter, (state) => ({ ...state, searchFilter: '' })),
  on(addToTagFilter, ({ filterTags, ...state }, { tag }) => ({
    ...state,
    filterTags: filterTags.includes(tag) ? filterTags : [...filterTags, tag],
  })),
  on(removeFromTagFilter, ({ filterTags, ...state }, { tag }) => ({
    ...state,
    filterTags: filterTags.filter((fTag) => fTag !== tag),
  })),
  on(resetTagFilter, (state) => ({ ...state, filterTags: [], filter: 'all' as VoyageStatus, filterByCompanyId: null })),
  on(setViewFilter, (state, { filter }) => ({ ...state, filter })),
  on(setCompanyFilter, (state, { companyId: filterByCompanyId }) => ({ ...state, filterByCompanyId })),
  on(toggleCheckVoyageUuid, (state, { uuid, checked }) => {
    const checkedVoyageUuids = [...state.checkedVoyageUuids];
    if (checked) {
      checkedVoyageUuids.push(uuid);
    } else {
      const idx = checkedVoyageUuids.findIndex((existingUuid) => existingUuid === uuid);
      checkedVoyageUuids.splice(idx, 1);
    }
    return { ...state, checkedVoyageUuids };
  }),
  on(setCheckedVoyageUuids, (state, { uuids: checkedVoyageUuids }) => ({ ...state, checkedVoyageUuids })),
  on(assignVoyageSuccess, (state, {}) => ({ ...state, checkedVoyageUuids: [] })),
  on(deleteReportSuccess, (state, { id: deleteId }) => {
    const voyage = { ...state.currentVoyageDetails };
    const currentVoyageDetails = {
      ...voyage,
      voyageLegs: voyage.voyageLegs?.map((voyageLeg) =>
        voyageLeg.reports ? { ...voyageLeg, reports: voyageLeg.reports.filter(({ id }) => id !== deleteId) } : voyageLeg
      ),
    };

    return {
      ...state,
      currentVoyageDetails,
      loading: false,
    };
  }),
  on(verifyVoyageSuccess, (state, { voyage }) => {
    const entries = [...state.entries];
    const voyageIndex = entries.findIndex(({ voyage: voyageEntry }) => voyageEntry.uuid === voyage.uuid);
    entries.splice(voyageIndex, 1);

    return {
      ...state,
      entries,
      loading: false,
    };
  }),
  on(unverifyVoyageSuccess, (state, { uuid: removeUuid }) => {
    const verfiedEntries = [...state.verifiedEntries];
    const removeIndex = verfiedEntries.findIndex(({ uuid }) => uuid === removeUuid);
    verfiedEntries.splice(removeIndex, 1);
    return {
      ...state,
      verfiedEntries,
      loading: false,
    };
  }),
  on(updateReportSuccess, (state, { report: updatedReport }) => {
    const {
      currentVoyageDetails: { voyageLegs: currentVoyageLegs, ...voyageDetails },
      entries: initialEntries,
    } = state;
    const voyageLegs = currentVoyageLegs?.map(({ reports, ...leg }) => ({
      ...leg,
      reports: reports.map((report) => (report.id === updatedReport.id ? { ...report, ...updatedReport } : report)),
    }));

    const entries = initialEntries.map((voyage) => {
      const { voyageLegs: initialVoyageLegs } = voyage;
      const updatedVoyageLegs = initialVoyageLegs?.map(({ reports, ...leg }) => ({
        ...leg,
        reports: reports?.map((report) => (report.id === updatedReport.id ? { ...report, ...updatedReport } : report)),
      }));
      return { ...voyage, voyageLegs: updatedVoyageLegs };
    });

    return {
      ...state,
      entries,
      currentVoyageDetails: { ...voyageDetails, voyageLegs },
      loading: false,
    };
  }),
  on(deleteMultipleReportsSuccess, (state, { ids }) => {
    const entries = state.entries.map((voyage) => {
      const { voyageLegs: initialVoyageLegs } = voyage;
      const voyageLegs = initialVoyageLegs?.map(({ reports, ...leg }) => ({
        ...leg,
        reports: reports.filter(({ id }) => !ids.includes(id)),
      }));
      return { ...voyage, voyageLegs };
    });
    return { ...state, entries, loading: false };
  })
);

export function reducer(state = initalState, action: Action): State {
  return reducerFn(state, action);
}
