import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BackendReport, PostReportResponse, Report } from 'src/types';
@Injectable({ providedIn: 'root' })
export class ReportService {
  siglarApiUrl = environment.siglarApi.url;

  constructor(private http: HttpClient) {}

  loadShipReports(imo: number, showAnomalies = false) {
    const url = showAnomalies ? `/api/v1/reports/${imo}?Estimates=1&Anomalies=1` : `/api/v1/reports/${imo}`;
    const reportsEndpointUrl = new URL(url, this.siglarApiUrl);
    return this.http.get<{ data: BackendReport[] }>(reportsEndpointUrl.href);
  }

  loadVoyageReports(voyageUuid: string) {
    const url = `/api/v1/reports/${voyageUuid}?Estimates=1&Anomalies=1`;
    const reportsEndpointUrl = new URL(url, this.siglarApiUrl);
    return this.http.get<{ data: BackendReport[] }>(reportsEndpointUrl.href);
  }

  createReport(report: Partial<BackendReport>, imo: number) {
    const reportsEndpointUrl = new URL(`/api/v1/reports/${imo}?bypassValidations=speed,distance`, this.siglarApiUrl);
    return this.http.post<PostReportResponse>(reportsEndpointUrl.href, report);
  }

  createMultipleReports(report: Partial<BackendReport>[], imo: number) {
    const reportsEndpointUrl = new URL(
      `/api/v1/reports/bulk/${imo}?bypassValidations=speed,distance,condition,portActivity`,
      this.siglarApiUrl
    );
    return this.http.post<PostReportResponse[]>(reportsEndpointUrl.href, report);
  }

  updateReport(report: Partial<BackendReport>, imo: number, id: number) {
    const reportsEndpointUrl = new URL(
      `/api/v1/reports/${imo}/${id}?bypassValidations=speed,distance`,
      this.siglarApiUrl
    );
    return this.http.put<{ data: any }>(reportsEndpointUrl.href, report);
  }

  setAnomalyResolution(data: { resolved: boolean; recommendation: string }, anormalyId: string) {
    const reportsEndpointUrl = new URL(`/api/v1/reports/${anormalyId}/anomaly`, this.siglarApiUrl);
    return this.http.put<{ data: any }>(reportsEndpointUrl.href, data);
  }

  updateMultipleReport(reports: Partial<BackendReport>[], imo: number) {
    const reportsEndpointUrl = new URL(
      `/api/v1/reports/bulk/${imo}?bypassValidations=speed,distance`,
      this.siglarApiUrl
    );
    return this.http.put<{ data: any }>(reportsEndpointUrl.href, reports);
  }

  patchReport(report: Partial<Report>, imo: number, id: string | number) {
    const reportsEndpointUrl = new URL(`/api/v1/reports/${imo}/${id}`, this.siglarApiUrl);
    return this.http.patch<{ data: any }>(reportsEndpointUrl.href, report);
  }

  deleteReport(id: number, imo: number) {
    const reportsEndpointUrl = new URL(`/api/v1/reports/${imo}/${id}`, this.siglarApiUrl);
    return this.http.delete<{ data: any[] }>(reportsEndpointUrl.href);
  }
}
