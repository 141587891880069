import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { CoreEffects } from './effects/core.effects';
import { CompanyEffects } from './effects/company.effects';
import { ReportEffects } from './effects/report.effects';
import { DashboardEffects } from './effects/dashboard.effects';
import { ShipEffects } from './effects/ship.effects';
import { VoyageEffects } from './effects/voyage.effects';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from '.';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AuthModule } from '@auth0/auth0-angular';
import { AuthEffects } from './effects/auth.effects';
import { UserEffects } from './effects/users.effects';
import { ToastrModule } from 'ngx-toastr';
import { PortEffects } from './effects/port.effects';

@NgModule({
  declarations: [], imports: [CommonModule,
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      cacheLocation: 'localstorage',
      authorizationParams: {
        redirect_uri: `${environment.appUrl}/${environment.auth0.redirect}`,
        audience: environment.auth0.audience,
      }
    }),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([
      AuthEffects,
      CoreEffects,
      CompanyEffects,
      ReportEffects,
      ShipEffects,
      VoyageEffects,
      DashboardEffects,
      UserEffects,
      PortEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    ToastrModule.forRoot()], providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, provideHttpClient(withInterceptorsFromDi())]
})
export class CoreModule { }