import { Action, createReducer, on } from '@ngrx/store';
import { Ship, ShipSearchResult, ShipUuid } from 'src/types';
import {
  createLink,
  createLinkFailure,
  createLinkSuccess,
  loadLink,
  getLinkFailure,
  loadLinkSuccess,
  getShip,
  getShipFailure,
  getShipSuccess,
  loadShips,
  loadShipsFailure,
  loadShipsSuccess,
  loadShipUuids,
  loadShipUuidsFailure,
  loadShipUuidsSuccess,
  searchShipsFailure,
  searchShipsSuccess,
  setSelectedShip,
} from '../actions/ship.actions';

export interface State {
  entries: Ship[];
  shipUuids: ShipUuid[];
  searchResults: ShipSearchResult[];
  selectedShipImo: number;
  error: string;
  loading: boolean;
}

export const initalState: State = {
  entries: [],
  shipUuids: [],
  searchResults: null,
  selectedShipImo: null,
  error: '',
  loading: false,
};

export const reducerFn = createReducer(
  initalState,
  on(loadShips, loadShipUuids, createLink, loadLink, getShip, (state) => ({
    ...state,
    loading: true,
  })),
  on(loadShipsSuccess, (state, { ships: entries }) => ({ ...state, entries, loading: false })),
  on(loadShipUuidsSuccess, (state, { shipUuids }) => ({ ...state, shipUuids, loading: false })),
  on(createLinkSuccess, (state) => ({ ...state, loading: false })),
  on(loadLinkSuccess, (state, { shipUuid }) => {
    const shipUuids = [...state.shipUuids.filter(({ uuid }) => uuid !== shipUuid.uuid), shipUuid];
    return {
      ...state,
      loading: false,
      shipUuids,
    };
  }),
  on(getShipSuccess, (state, { ship }) => {
    const entries = [...state.entries.filter(({ imo }) => imo !== ship.imo), ship];
    return {
      ...state,
      loading: false,
      entries,
    };
  }),
  on(setSelectedShip, (state, { imo }) => ({
    ...state,
    selectedShipImo: Number(imo),
  })),
  on(searchShipsSuccess, (state, { ships }) => ({
    ...state,
    searchResults: ships,
    loading: false,
  })),
  on(
    loadShipsFailure,
    loadShipUuidsFailure,
    createLinkFailure,
    getLinkFailure,
    getShipFailure,
    searchShipsFailure,
    (state, { error }) => ({
      ...state,
      error,
      loading: false,
    })
  )
);

export function reducer(state = initalState, action: Action): State {
  return reducerFn(state, action);
}
