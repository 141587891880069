import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as fromPorts from '../actions/port.actions';
import { PortService } from '../services/port.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class PortEffects {
  constructor(
    private actions$: Actions,
    private portService: PortService,
    private toastr: ToastrService,
    private _dialog: MatDialog,
  ) {}

  loadPorts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        fromPorts.loadPorts,
        fromPorts.createPortSuccess,
        fromPorts.updatePortSuccess,
        fromPorts.deletePortSuccess,
      ),
      switchMap(() =>
        this.portService.getPorts().pipe(
          map(({ data: ports }) => fromPorts.loadPortsSuccess({ ports })),
          catchError((error) => of(fromPorts.loadPortsFailure({ error }))),
        ),
      ),
    );
  });

  createPort$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromPorts.createPort),
      mergeMap(({ port }) =>
        this.portService.createPort(port).pipe(
          map(({ data }) => fromPorts.createPortSuccess({ port: data })),
          catchError(({ error }) => of(fromPorts.createPortFailure({ error }))),
        ),
      ),
    );
  });

  updatePort$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromPorts.updatePort),
      mergeMap(({ port, portId }) =>
        this.portService.updatePort(port, portId).pipe(
          map(({ data }) => fromPorts.updatePortSuccess({ port: data, portId })),
          catchError(({ error }) => of(fromPorts.updatePortFailure({ error }))),
        ),
      ),
    );
  });

  deletePort$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromPorts.deletePort),
      mergeMap(({ portId }) =>
        this.portService.deletePort(portId).pipe(
          map(() => fromPorts.deletePortSuccess({ portId })),
          catchError((error) => of(fromPorts.deletePortFailure({ error }))),
        ),
      ),
    );
  });

  success$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromPorts.createPortSuccess, fromPorts.updatePortSuccess, fromPorts.deletePortSuccess),
        tap(({ type }) => {
          this._dialog.closeAll();
          this.toastr.success('The operation was successful!', type, { timeOut: 2000 });
        }),
      );
    },
    { dispatch: false },
  );

  error$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromPorts.createPortFailure, fromPorts.updatePortFailure, fromPorts.deletePortFailure),
        tap(({ type, error }) => {
          const errorMessage = error ? error?.error || error?.status : 'Something went wrong';
          this.toastr.error(errorMessage, type, { timeOut: 4000 });
        }),
      );
    },
    { dispatch: false },
  );
}
