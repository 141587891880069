import { Action, createReducer, on } from '@ngrx/store';
import { initialize } from '../actions/core.actions';

export interface State {
  shipParameter: string;
  source: any;
  loading: boolean;
}

export const initalState: State = {
  shipParameter: '',
  source: 'local',
  loading: false,
};

export const reducerFn = createReducer(
  initalState,
  on(initialize, (state, { source, name: shipParameter }) => ({ ...state, source, shipParameter }))
);

export function reducer(state = initalState, action: Action): State {
  return reducerFn(state, action);
}

export const getCoreState = (state: State) => state;
