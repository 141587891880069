import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { convertToBackendReport, convertToBulkBackendReport, convertToBulkEditBackendReport } from 'src/utils';
import * as fromReports from '../actions/report.actions';
import * as fromVoyages from '../actions/voyage.actions';
import { ReportService } from '../services/report.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class ReportEffects {
  constructor(
    private actions$: Actions,
    private reportService: ReportService,
    private _dialog: MatDialog,
    private store: Store<any>,
    private toastr: ToastrService,
  ) {}

  loadShipReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReports.loadShipReports, fromReports.updateShipReportSuccess),
      switchMap(({ imo, showAnomalies }) =>
        this.reportService.loadShipReports(imo, showAnomalies).pipe(
          map(({ data: reports }) => fromReports.loadShipReportsSuccess({ reports })),
          catchError((error) => of(fromReports.loadReportsFailure({ error }))),
        ),
      ),
    );
  });

  loadVoyageReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        fromReports.loadVoyageReports,
        fromVoyages.setVoyageReportRangeSuccess,
        fromVoyages.resetVoyageReportRangeSuccess,
      ),
      switchMap(({ uuid }) =>
        this.reportService.loadVoyageReports(uuid).pipe(
          map(({ data: reports }) => fromReports.loadVoyageReportsSuccess({ reports })),
          catchError((error) => of(fromReports.loadReportsFailure({ error }))),
        ),
      ),
    );
  });

  createReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReports.createReport),
      mergeMap(({ report: reportForm, imo, source, voyageUuid }) => {
        const report = convertToBackendReport(reportForm, source);
        return this.reportService.createReport(report, imo).pipe(
          map((response) => fromReports.createReportSuccess({ response, imo, voyageUuid })),
          catchError(({ error }) => of(fromReports.createReportFailure({ error }))),
        );
      }),
    );
  });

  createReportSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReports.createReportSuccess),
      map(({ imo, voyageUuid: uuid }) => {
        if (uuid) {
          this.store.dispatch(fromVoyages.loadVoyageDetails({ uuid }));
          this.store.dispatch(fromReports.loadVoyageReports({ uuid }));
        }
        this.store.dispatch(fromReports.loadShipReports({ imo }));
        this._dialog.closeAll();
        return fromReports.clearReportStatus();
      }),
    );
  });

  createMultipleReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReports.createMultipleReports),
      mergeMap(({ reports, imo, voyageUuid }) => {
        const formattedReports = reports.map(({ ...report }) => convertToBulkBackendReport(report));
        return this.reportService.createMultipleReports(formattedReports, imo).pipe(
          map((responses) => fromReports.createMultipleReportsSuccess({ responses, imo, voyageUuid })),
          catchError(({ error }) => of(fromReports.createMultipleReportsFailure({ error }))),
        );
      }),
    );
  });

  createMultipleReportsSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReports.createMultipleReportsSuccess),
      mergeMap(({ responses, imo, voyageUuid: uuid, type }) =>
        of(responses).pipe(
          map(({ duplicates = [], rejected = [], acceptedReports = [] }) => {
            if (acceptedReports?.length && !rejected?.length && !duplicates?.length) {
              const toast = this.toastr.success('Reports imported successfully', type, { timeOut: 2000 });
              toast.onHidden.subscribe(() => {
                this._dialog.closeAll();
              });
            }
            this.store.dispatch(fromVoyages.loadVoyageDetails({ uuid }));
            this.store.dispatch(fromReports.loadVoyageReports({ uuid }));
            return fromReports.loadShipReports({ imo });
          }),
        ),
      ),
    );
  });

  updateMultipleReportsSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReports.updateMultipleReportsSuccess),
      mergeMap(({ responses, voyageUuid, type, imo }) =>
        of(responses).pipe(
          tap(({ rejected = [], acceptedReports = [] }) => {
            if (acceptedReports?.length && !rejected?.length) {
              const toast = this.toastr.success('Reports updated successfully', type, { timeOut: 5000 });
              toast.onHidden.subscribe(() => {
                this._dialog.closeAll();
              });
            }
          }),
          mergeMap(() => {
            const loadVoyageReportsAction = fromReports.loadVoyageReports({ uuid: voyageUuid });
            const loadShipReportsAction = fromReports.loadShipReports({ imo });
            return [loadVoyageReportsAction, loadShipReportsAction];
          }),
        ),
      ),
    );
  });

  updateReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReports.updateReport),
      mergeMap(({ report: { id, ...report }, imo }) => {
        const formattedId = Number(id);
        const formattedReport = convertToBackendReport({ ...report, id: formattedId });
        return this.reportService.updateReport(formattedReport, imo, formattedId).pipe(
          map(() => fromReports.updateReportSuccess({ report: { id: formattedId, ...formattedReport }, imo })),
          catchError(({ error }) => of(fromReports.updateReportFailure({ error }))),
        );
      }),
    );
  });

  updateShipReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReports.updateShipReport),
      mergeMap(({ report: { id, ...report }, imo }) => {
        const formattedId = Number(id);
        const formattedReport = convertToBackendReport({ ...report, id: formattedId });
        return this.reportService.updateReport(formattedReport, imo, formattedId).pipe(
          map(() => fromReports.updateShipReportSuccess({ imo })),
          catchError(({ error }) => of(fromReports.updateReportFailure({ error }))),
        );
      }),
    );
  });

  updateMultipleReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReports.updateMultipleReports),
      mergeMap(({ reports, imo, voyageUuid }) => {
        const formattedReports = reports.map(({ id, ...report }) => {
          const formattedId = Number(id);
          const formattedReport = convertToBulkEditBackendReport(report);
          return { id: formattedId, ...formattedReport };
        });
        return this.reportService.updateMultipleReport(formattedReports, imo).pipe(
          map((responses) => fromReports.updateMultipleReportsSuccess({ responses, imo, voyageUuid })),
          catchError(({ error }) => of(fromReports.updateMultipleReportsFailure({ error }))),
        );
      }),
    );
  });

  updateReportSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReports.updateReportSuccess, fromReports.updateShipReportSuccess),
      map(() => {
        this._dialog.closeAll();
        return fromReports.clearReportStatus();
      }),
    );
  });

  setAnomalyResolution$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReports.setAnomalyResolution),
      mergeMap(({ data, anomalyId }) =>
        this.reportService.setAnomalyResolution(data, anomalyId).pipe(
          map(() => fromReports.setAnomalyResolutionSuccess({ data, anomalyId })),
          catchError((error) => of(fromReports.setAnomalyResolutionFailure({ error }))),
        ),
      ),
    );
  });

  deleteMultipleReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReports.deleteMultipleReports),
      mergeMap(({ ids, imo }) => {
        const requests = ids.map((id) => this.reportService.deleteReport(id, imo));
        return forkJoin(requests).pipe(
          map(() => fromReports.deleteMultipleReportsSuccess({ ids, imo })),
          catchError(({ error }) => of(fromReports.deleteMultipleReportsFailure({ error }))),
        );
      }),
    );
  });

  deleteReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromReports.deleteReport),
      mergeMap(({ id, imo }) =>
        this.reportService.deleteReport(id, imo).pipe(
          map((response) => fromReports.deleteReportSuccess({ response, id, imo })),
          catchError((error) => of(fromReports.deleteReportFailure({ error }))),
        ),
      ),
    );
  });

  promptDeleteReport$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromReports.promptDeleteReport),
        tap(({ report, imo }) => {
          const { id, reportDate } = report;

          const formattedReportDate = moment(reportDate).format('DD. MMM YYYY');

          this._dialog
            .open(ConfirmationDialogComponent, {
              disableClose: true,
              width: '600px',
              height: '400px',
              data: {
                title: 'Confirm delete report',
                message: `
              <div>Are you sure you want to delete the following report?</div>
              <div><b>Report ID:</b> ${id}</div>
              <div><b>Ship (IMO):</b> ${imo}</div>
              <div><b>Report date:</b> ${formattedReportDate}</div>
              <div><b>Report type:</b> ${report.reportType.toUpperCase()}</div>
              `,
                btnOkText: 'Delete Report',
              },
            })
            .afterClosed()
            .subscribe((data: boolean) => {
              if (data) {
                this.store.dispatch(fromReports.deleteReport({ id: Number(id), imo }));
              }
            });
        }),
      );
    },
    { dispatch: false },
  );

  openDeleteMultipleReportsPrompt$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromReports.promptDeleteMultipleReports),
        tap(({ ids, imo }) => {
          let message = `<p>Are you sure you want to delete the following reports?</p>`;
          ids.forEach((id) => (message = message + `<p><span class="color-light">Report ID:</span> ${id}</p>`));

          this._dialog
            .open(ConfirmationDialogComponent, {
              disableClose: true,
              width: '600px',
              height: '400px',
              data: { title: 'Confirm delete reports', message, btnOkText: 'Delete reports' },
            })
            .afterClosed()
            .subscribe((data: boolean) => {
              if (data) {
                this.store.dispatch(fromReports.deleteMultipleReports({ ids, imo }));
              }
            });
        }),
      );
    },
    { dispatch: false },
  );

  showReportWarning$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromReports.showReportWarning),
        tap(({ key, value }) =>
          this.toastr.success(
            `There seems to be errors in input field ${key} with value ${value}, invalid inputs may be replaced by default values. Please check the values and correct appropriately.`,
            'Report Input Error',
            { timeOut: 0, extendedTimeOut: 0 },
          ),
        ),
      );
    },
    { dispatch: false },
  );

  success$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          fromReports.createReportSuccess,
          fromReports.updateReportSuccess,
          fromReports.updateShipReportSuccess,
          fromReports.deleteReportSuccess,
          fromReports.setAnomalyResolutionSuccess,
          fromReports.deleteMultipleReportsSuccess,
        ),
        tap(({ type }) => this.toastr.success('The operation was successful!', type, { timeOut: 2000 })),
      );
    },
    { dispatch: false },
  );

  error$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          fromReports.createReportFailure,
          fromReports.createMultipleReportsFailure,
          fromReports.updateReportFailure,
          fromReports.setAnomalyResolutionFailure,
          fromReports.updateMultipleReportsFailure,
          fromReports.deleteMultipleReportsFailure,
        ),
        tap(({ type, error }) => {
          const errorMessage = error ? error?.errorDetail || error?.error || error?.status : 'Something went wrong';
          this.toastr.error(errorMessage, type, { timeOut: 5000 });
        }),
      );
    },
    { dispatch: false },
  );
}
