import { createReducer, on, Action } from '@ngrx/store';
import {
  loadDashboardVoyages,
  loadDashboardVoyagesSuccess,
  loadDashboardVoyagesFailure,
  loadDashboardCompanies,
  loadDashboardCompaniesSuccess,
  loadDashboardCompaniesFailure,
  loadDashboardAnomalies,
  loadDashboardAnomaliesFailure,
  loadDashboardAnomaliesSuccess,
  setDashboardFilters,
} from '../actions/dashboard.actions';

export interface State {
  voyages: any;
  companies: any[];
  anomalies: any;
  filters: any;
  error: string;
  loading: boolean;
}

export const initialState: State = {
  voyages: null,
  companies: [],
  anomalies: null,
  filters: {
    startIndex: 0,
    endIndex: 20,
  },
  error: '',
  loading: false,
};

export const reducerFn = createReducer(
  initialState,
  on(loadDashboardVoyages, loadDashboardCompanies, loadDashboardAnomalies, (state) => ({
    ...state,
    loading: true,
  })),
  on(loadDashboardVoyagesSuccess, (state, { data: voyages }) => ({ ...state, voyages, loading: false })),
  on(loadDashboardVoyagesFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(loadDashboardCompaniesSuccess, (state, { data: companies }) => ({ ...state, companies, loading: false })),
  on(loadDashboardCompaniesFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(loadDashboardAnomaliesSuccess, (state, { data: anomalies }) => ({ ...state, anomalies, loading: false })),
  on(loadDashboardAnomaliesFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  })),
  on(setDashboardFilters, (state, { filters }) => ({
    ...state,
    filters: { ...state.filters, ...filters },
    loading: false,
  }))
);

export function reducer(state = initialState, action: Action): State {
  return reducerFn(state, action);
}
