import { Action, createReducer, on } from '@ngrx/store';
import { Company } from 'src/types';
import {
  createCompany,
  createCompanyFailure,
  deleteCompany,
  loadCompanies,
  loadCompaniesFailure,
  loadCompaniesSuccess,
  updateCompany,
  setSearchFilter,
  clearSearchFilter,
  deleteCompanyFailure,
} from '../actions/company.actions';

export interface State {
  entries: Company[];
  searchFilter: string;
  error: string;
  loading: boolean;
}

export const initalState: State = {
  entries: [],
  searchFilter: '',
  error: '',
  loading: false,
};

export const reducerFn = createReducer(
  initalState,
  on(loadCompanies, createCompany, updateCompany, deleteCompany, (state) => ({ ...state, loading: true })),
  on(loadCompaniesSuccess, (state, { companies: entries }) => ({ ...state, entries, loading: false })),
  on(
    loadCompaniesFailure,
    createCompanyFailure,
    createCompanyFailure,
    createCompanyFailure,
    deleteCompanyFailure,
    (state, { error }) => ({
      ...state,
      error,
      loading: false,
    })
  ),
  on(setSearchFilter, (state, { searchValue: searchFilter }) => ({ ...state, searchFilter })),
  on(clearSearchFilter, (state) => ({ ...state, searchFilter: '' }))
);

export function reducer(state = initalState, action: Action): State {
  return reducerFn(state, action);
}
