import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { AuthUser } from 'src/types';

@Injectable({ providedIn: 'root' })
export class AuthService {
  /* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
  private _authFlag = 'isLoggedIn';
  private _accessTokenFlag = 'access_token';
  private _returnUrl = 'returnUrl';
  private _onAuthSuccessUrl = '/home';
  private _onAuthFailureUrl = '/login';

  constructor(private auth0Service: Auth0Service, private http: HttpClient) {}

  get authSuccessUrl(): string {
    return this._onAuthSuccessUrl;
  }

  get authFailureUrl(): string {
    return this._onAuthFailureUrl;
  }

  get authenticated(): boolean {
    return JSON.parse(localStorage.getItem(this._authFlag));
  }

  resetAuthFlag() {
    localStorage.removeItem(this._authFlag);
  }

  login() {
    return this.auth0Service.loginWithPopup();
  }

  loginWithRedirect(target = '/voyages/active') {
    localStorage.setItem(this._returnUrl, target);

    return this.auth0Service.loginWithRedirect({
      appState: { target },
    });
  }

  getAccessToken() {
    return this.auth0Service.getAccessTokenSilently();
  }

  isAuthenticated() {
    return this.auth0Service.isAuthenticated$;
  }

  setAuth(token: string) {
    localStorage.setItem(this._authFlag, 'true');
    localStorage.setItem(this._accessTokenFlag, token);
  }

  getAuth() {
    return localStorage.getItem(this._accessTokenFlag);
  }

  logout() {
    const { appUrl } = environment;
    const { href: returnTo } = new URL('/auth', appUrl);

    localStorage.setItem(this._authFlag, JSON.stringify(false));
    localStorage.setItem(this._accessTokenFlag, JSON.stringify(''));
    localStorage.removeItem(this._returnUrl);

    return this.auth0Service.logout({
      logoutParams: { returnTo }
    });
  }

  getAuthenticatedUser() {
    const { url: siglarApiUrl } = environment.siglarApi;
    const { href: userEndpointUrl } = new URL('/api/v1/user', siglarApiUrl);
    return this.http.get<AuthUser>(userEndpointUrl);
  }
}
