import { Action, createReducer, on } from '@ngrx/store';
import { SiglarUser } from 'src/types';
import {
  createUser,
  createUserFailure,
  deleteUser,
  loadUsers,
  loadUsersFailure,
  loadUsersSuccess,
  updateUser,
  setSearchFilter,
  clearSearchFilter,
  loadOpsUsers,
  loadOpsUsersSuccess,
  loadOpsUsersFailure,
} from '../actions/users.actions';

export interface State {
  entries: SiglarUser[];
  opsTeam: SiglarUser[];
  searchFilter: string;
  error: string;
  loading: boolean;
}

export const initalState: State = {
  entries: [],
  opsTeam: [],
  searchFilter: '',
  error: '',
  loading: false,
};

export const reducerFn = createReducer(
  initalState,
  on(loadUsers, loadOpsUsers, createUser, updateUser, deleteUser, (state) => ({ ...state, loading: true })),
  on(loadUsersSuccess, (state, { users }) => {
    const entries = users.map((user) => ({ ...user, details: `${user.name} (${user.email})` }));
    return { ...state, entries, loading: false };
  }),
  on(loadOpsUsersSuccess, (state, { users }) => {
    const opsTeam = users.map((user) => ({ ...user, details: `${user.name} (${user.email})` }));
    return { ...state, opsTeam, loading: false };
  }),
  on(
    loadUsersFailure,
    loadOpsUsersFailure,
    createUserFailure,
    createUserFailure,
    createUserFailure,
    (state, { error }) => ({
      ...state,
      error,
      loading: false,
    })
  ),
  on(setSearchFilter, (state, { searchValue: searchFilter }) => ({ ...state, searchFilter })),
  on(clearSearchFilter, (state) => ({ ...state, searchFilter: '' }))
);

export function reducer(state = initalState, action: Action): State {
  return reducerFn(state, action);
}
