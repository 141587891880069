import { Action, createReducer, on } from '@ngrx/store';
import { Country, Port, PortFilters } from 'src/types';
import { } from '../actions/users.actions';
import {
  loadPorts,
  loadPortsSuccess,
  loadPortsFailure,
  setFilter,
} from '../actions/port.actions';

export interface State {
  entries: Port[];
  countries: Country[];
  ports: Map<string, Port>;
  filters: PortFilters;
  error: string;
  loading: boolean;
}

export const initalState: State = {
  entries: [],
  countries: [],
  ports: new Map<string, Port>(),
  filters: {
    totalPorts: 0,
    searchValue: '',
    searchSuggestion: '',
    page: 0,
    pageSize: 20,
    startIndex: 0,
    endIndex: 20,
    sortBy: 'name',
    isAsc: true,
  },
  error: '',
  loading: false,
};

export const reducerFn = createReducer(
  initalState,
  on(loadPorts, (state): State => ({ ...state, loading: true })),
  on(loadPortsSuccess, (state, { ports = [] }) => {
    const completePortsMap = ports.reduce((portsMap, port) => {
      if (port.unlocode) {
        portsMap.set(port.unlocode, port);
      }
      return portsMap;
    }, new Map<string, Port>());

    const uniqueSet = new Set<string>();

    const countries = ports.filter(port => {
      const uniqueKey = `${port.country}-${port.countryShort}`;
      if (!uniqueSet.has(uniqueKey) && (port.country && port.countryShort)) {
        uniqueSet.add(uniqueKey);
        return true;
      }
      return false;
    }).map(port => ({
      long: port.country,
      short: port.countryShort
    }));

    return {
      ...state,
      countries,
      loading: false,
      entries: ports,
      ports: completePortsMap,
    };
  }),
  on(loadPortsFailure, (state, { error }): State => ({ ...state, error, loading: false })),
  on(
    setFilter,
    (state, { searchValue = state.filters.searchValue || '', searchSuggestion = '', page = 0, pageSize = 20, sortBy = 'name', orderBy = 'asc' }): State => {
      const startIndex = page * pageSize;
      const endIndex = startIndex + pageSize;
      const isAsc = orderBy === 'asc';

      return {
        ...state,
        filters: { ...state.filters, searchValue, searchSuggestion, startIndex, endIndex, sortBy, isAsc, page, pageSize },
      };
    }
  ),
);

export function reducer(state = initalState, action: Action): State {
  return reducerFn(state, action);
}
