import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Company } from 'src/types';

@Injectable({ providedIn: 'root' })
export class CompanyService {
  siglarApiUrl = environment.siglarApi.url;

  constructor(private http: HttpClient) {}

  loadCompanies() {
    const companiesEndpointUrl = new URL('/api/v1/companies', this.siglarApiUrl);
    return this.http.get<{ body: { complete: Company[] } }>(companiesEndpointUrl.href);
  }

  createCompany(company: any) {
    const createCompanyEndpointUrl = new URL('/api/v1/companies', this.siglarApiUrl);
    return this.http.post<{ status: string; uuid: string }>(createCompanyEndpointUrl.href, company);
  }

  updateCompany(company: any) {
    const updateCompanyEndpointUrl = new URL(`/api/v1/companies/${company.uuid}`, this.siglarApiUrl);
    return this.http.put<Company>(updateCompanyEndpointUrl.href, company);
  }

  deleteCompany(id: string) {
    const deleteCompanyEndpointUrl = new URL(`/api/v1/companies/${id}`, this.siglarApiUrl);
    return this.http.delete<{ deleted: boolean }>(deleteCompanyEndpointUrl.href);
  }
}
