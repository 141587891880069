import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Store } from '@ngrx/store';
import { loginRedirect, promptLogin } from '../actions/auth.actions';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private store: Store, private router: Router) {}

  checkToken(token: string) {
    if (token) {
      const expiry = JSON.parse(atob(token.split('.')[1])).exp;
      const almostExpired = Math.floor(new Date().getTime() / 1000) + 600 >= expiry;
      if (almostExpired) {
        this.store.dispatch(promptLogin());
      }
    } else {
      setTimeout(() => {
        this.store.dispatch(loginRedirect({ returnUrl: this.router.url }));
      }, 5000);
    }
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authToken = this.authService.getAuth();
    this.checkToken(authToken);

    if (authToken) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError(
        (err) =>
          new Observable<HttpEvent<any>>((observer) => {
            if (err instanceof HttpErrorResponse) {
              const errResp = <HttpErrorResponse>err;
              if (errResp.status === 401 || err.status === 403) {
                this.store.dispatch(loginRedirect({ returnUrl: this.router.url }));
              }
            }
            observer.error(err);
            observer.complete();
          })
      )
    );
  }
}
