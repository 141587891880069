import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  siglarApiUrl = environment.siglarApi.url;

  constructor(private http: HttpClient) { }

  getDashboardVoyages(filter: string) {
    const query = filter ? `?${filter}` : '';
    const dashboardVoyagesEndpointUrl = new URL(`/api/v1/dashboard/voyages${query}`, this.siglarApiUrl);
    return this.http.get<{data: any}>(dashboardVoyagesEndpointUrl.href);
  }

  getDashboardCompanies(filter: string) {
    const query = filter ? `?${filter}` : '';
    const dashboardCompaniesEndpointUrl = new URL(`/api/v1/dashboard/companies${query}`, this.siglarApiUrl);
    return this.http.get<{data: any}>(dashboardCompaniesEndpointUrl.href);
  }

  getDashboardAnomalies(filter: string) {
    const query = filter ? `?${filter}` : '';
    const voyageAnomaliesEndpointUrl = new URL(`/api/v1/dashboard/anomalies${query}`, this.siglarApiUrl);
    return this.http.get<{data: any}>(voyageAnomaliesEndpointUrl.href);
  }
}