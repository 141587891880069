import { ActionReducerMap, ActionReducer, MetaReducer, createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromAuth from './reducers/auth.reducers';
import * as fromCore from './reducers/core.reducers';
import * as fromShips from './reducers/ship.reducers';
import * as fromReports from './reducers/report.reducers';
import * as fromDashboard from './reducers/dashboard.reducers';
import * as fromVoyages from './reducers/voyage.reducers';
import * as fromCompanies from './reducers/company.reducers';
import * as fromUsers from './reducers/users.reducers';
import * as fromPorts from './reducers/port.reducers';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from 'src/environments/environment';

export interface AppState {
  auth: fromAuth.State;
  core: fromCore.State;
  ships: fromShips.State;
  dashboard: fromDashboard.State;
  reports: fromReports.State;
  voyages: fromVoyages.State;
  companies: fromCompanies.State;
  users: fromUsers.State;
  ports: fromPorts.State;
}

export const reducers: ActionReducerMap<AppState> = {
  auth: fromAuth.reducer,
  core: fromCore.reducer,
  ships: fromShips.reducer,
  dashboard: fromDashboard.reducer,
  reports: fromReports.reducer,
  voyages: fromVoyages.reducer,
  companies: fromCompanies.reducer,
  users: fromUsers.reducer,
  ports: fromPorts.reducer,
};

export const localStorageSyncReducer = (reducer: ActionReducer<AppState>): ActionReducer<AppState> =>
  localStorageSync({
    keys: [{ voyages: ['filters'] }],
    rehydrate: true,
  })(reducer);

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => reducer(state, action);
}

export const metaReducers: Array<MetaReducer<AppState, any>> = !environment.production
  ? [debug, localStorageSyncReducer]
  : [localStorageSyncReducer];

export const selectCoreState = createFeatureSelector<fromCore.State>('core');
export const selectCore = createSelector(selectCoreState, (state) => state);
export const getCoreState = createSelector(selectCore, fromCore.getCoreState);
