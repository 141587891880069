import { Action, createReducer, on } from '@ngrx/store';
import { AuthUser } from 'src/types';
import {
  getAuth0Token,
  getAuthenticatedUser,
  getAuthenticatedUserFailure,
  getAuthenticatedUserSuccess,
  login,
  loginFailure,
  loginRedirect,
  promptLogin,
} from '../actions/auth.actions';

export const authFeatureKey = 'auth';

export interface State {
  user: AuthUser;
  permissions: string[][];
  roles: string[];
  isAuthenticated: boolean;
  promptLogin: boolean;
  loading: boolean;
}

export const initialState: State = {
  user: null,
  permissions: [],
  roles: [],
  isAuthenticated: false,
  promptLogin: false,
  loading: false,
};

export const reducerFn = createReducer(
  initialState,
  on(login, loginRedirect, getAuthenticatedUser, getAuth0Token, (state) => ({ ...state, loading: true })),
  on(loginFailure, (state) => ({ ...state, loading: false, isAuthenticated: false })),
  on(getAuthenticatedUserFailure, (state) => ({ ...state, loading: false })),
  on(getAuthenticatedUserSuccess, (state, { user }) => ({ ...state, ...user, loading: false, promptLogin: false })),
  on(promptLogin, (state) => ({ ...state, promptLogin: true }))
);

export function reducer(state = initialState, action: Action): State {
  return reducerFn(state, action);
}
