import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getAuthenticatedUser } from './core/actions/auth.actions';
import { AuthService } from './core/services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sc-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit, OnDestroy {
  private _authSub: Subscription;
  private _tokenSub: Subscription;
  constructor(private _authService: AuthService, private _store: Store, private _router: Router) {}

  ngOnInit(): void {
    this._authSub = this._authService.isAuthenticated().subscribe((authenticated) => {
      if (authenticated) {
        this._tokenSub = this._authService.getAccessToken().subscribe((token) => {
          this._authService.setAuth(token);
          this._store.dispatch(getAuthenticatedUser());
        });
      } else {
        this._router.navigate(['/auth']);
      }
    });
  }

  ngOnDestroy(): void {
    if (this._authSub) {
      this._authSub.unsubscribe();
    }
    if (this._tokenSub) {
      this._tokenSub.unsubscribe();
    }
  }
}
