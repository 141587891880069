import { Action, createReducer, on } from '@ngrx/store';
import { BackendReport, ExcelReport, Report } from 'src/types';
import {
  deleteMultipleReportsSuccess,
  deleteReportFailure,
  deleteReportSuccess,
  loadShipReports,
  loadReportsFailure,
  loadShipReportsSuccess,
  resetReports,
  clearImportStatus,
  updateMultipleReportsSuccess,
  updateReportSuccess,
  createMultipleReportsFailure,
  createMultipleReportsSuccess,
  clearReportStatus,
  createReportFailure,
  updateReportFailure,
  setAnomalyResolutionSuccess,
  updateMultipleReportsFailure,
  clearMultipleReportsStatus,
  loadVoyageReports,
  loadVoyageReportsSuccess,
} from '../actions/report.actions';

export interface State {
  entries: BackendReport[];
  voyageReports: BackendReport[];
  currentReport: Report;
  excelImportStatus: {
    status: string;
    rejected: ExcelReport[];
    duplicates: ExcelReport[];
    acceptedReports: Report[];
    loading: boolean;
  };
  multipleUpdateStatus: {
    status: string;
    rejected: Report[];
    acceptedReports: Report[];
    loading: boolean;
  };
  errors: {
    error: string;
    errorFields: object;
    errorsList: string[];
    status: string;
  };
  loading: boolean;
}

export const initalState: State = {
  entries: [],
  voyageReports: [],
  currentReport: null,
  loading: false,
  errors: {
    error: null,
    errorFields: {},
    errorsList: [],
    status: null,
  },
  excelImportStatus: { status: '', rejected: [], duplicates: [], acceptedReports: [], loading: false },
  multipleUpdateStatus: {
    status: '',
    rejected: [],
    acceptedReports: [],
    loading: false,
  },
};

export const reducerFn = createReducer(
  initalState,
  on(loadShipReports, loadVoyageReports, (state): State => ({ ...state, entries: [], loading: true })),
  on(loadShipReportsSuccess, (state, { reports = [] }): State => ({
    ...state,
    entries: reports ? reports : [],
    loading: false,
  })),
  on(loadVoyageReportsSuccess, (state, { reports = [] }): State => ({
    ...state,
    voyageReports: reports ? reports : [],
    loading: false,
  })),
  on(loadReportsFailure, deleteReportFailure, createMultipleReportsFailure, (state, { error: errors }): State => ({
    ...state,
    errors,
    loading: false,
  })),
  on(updateReportSuccess, (state, { report: updatedReport }) => {
    const reportedTimeLocal = `${String(updatedReport?.reportedTime).substring(0, 10)}T${updatedReport?.localTime}:00${
      updatedReport?.timezone
    }`;
    const entries = state.entries.map((report) =>
      report.id === updatedReport.id ? { ...report, ...updatedReport, reportedTimeLocal } : report
    );
    const voyageReports = state.voyageReports.map((report) =>
      report.id === updatedReport.id ? { ...report, ...updatedReport, reportedTimeLocal } : report
    );
    return { ...state, entries, voyageReports, loading: false };
  }),
  on(createReportFailure, updateReportFailure, updateMultipleReportsFailure, (state, { error: errors }): State => ({
    ...state,
    errors,
  })),
  on(clearImportStatus, (state): State => ({
    ...state,
    excelImportStatus: { status: '', rejected: [], duplicates: [], acceptedReports: [], loading: false },
  })),
  on(clearReportStatus, (state): State => ({
    ...state,
    errors: { error: null, errorFields: null, errorsList: [], status: null },
  })),
  on(createMultipleReportsSuccess, (state, { responses }): State => ({
    ...state,
    excelImportStatus: { ...responses },
    loading: false,
  })),
  on(updateMultipleReportsSuccess, (state, { responses }): State => ({
    ...state,
    multipleUpdateStatus: { ...responses },
    loading: false,
  })),
  on(clearMultipleReportsStatus, (state): State => ({
    ...state,
    multipleUpdateStatus: { status: '', rejected: [], acceptedReports: [], loading: false },
  })),
  on(deleteReportSuccess, (state, { id: deleteId }) => ({
    ...state,
    entries: state.entries.filter(({ id }) => id !== deleteId),
    voyageReports: state.voyageReports.filter(({ id }) => id !== deleteId),
    loading: false,
  })),
  on(deleteMultipleReportsSuccess, (state, { ids }) => {
    const entries = state.entries.filter(({ id }) => !ids.includes(id));
    const voyageReports = state.voyageReports.filter(({ id }) => !ids.includes(id));
    return {
      ...state,
      entries,
      voyageReports,
      loading: false,
    };
  }),
  on(setAnomalyResolutionSuccess, (state, { data, anomalyId }) => {
    const entries = state.entries.map((report) => {
      const anomalies = report.anomalies.map((anomaly) =>
        anomaly.id === anomalyId ? { ...anomaly, ...data } : anomaly
      );
      return { ...report, anomalies };
    });
    const voyageReports = state.voyageReports.map((report) => {
      const anomalies = report.anomalies.map((anomaly) =>
        anomaly.id === anomalyId ? { ...anomaly, ...data } : anomaly
      );
      return { ...report, anomalies };
    });

    return { ...state, entries, voyageReports, loading: false };
  }),
  on(resetReports, (state): State => ({ ...state, entries: [] }))
);

export function reducer(state = initalState, action: Action): State {
  return reducerFn(state, action);
}
