import { concatLatestFrom } from '@ngrx/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { from, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { backendVoyage, getUserDefinedFilters, typedKeys } from 'src/utils';
import * as fromReports from '../actions/report.actions';
import * as fromShips from '../actions/ship.actions';
import * as fromVoyages from '../actions/voyage.actions';
import { selectShipUuids } from '../selectors/ship.selectors';
import { selectVoyages } from '../selectors/voyage.selectors';
import { VoyageService } from '../services/voyage.service';
import { VoyageFilters } from 'src/types';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class VoyageEffects {
  constructor(
    private actions$: Actions,
    private voyageService: VoyageService,
    private store: Store<any>,
    private _dialog: MatDialog,
    private toastr: ToastrService,
    private router: Router,
  ) {}

  loadVoyagesList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.loadVoyagesList),
      switchMap(({ filters }) =>
        this.voyageService.loadVoyageList(filters).pipe(
          concatMap(({ data: voyages, status: { total: count } }) => [
            fromVoyages.loadVoyagesListSuccess({ voyages, count }),
          ]),
          catchError((error) => of(fromVoyages.loadVoyagesListFailure({ error }))),
        ),
      ),
    );
  });

  loadVoyageAnomalies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.loadVoyageAnomalies, fromReports.setAnomalyResolutionSuccess),
      switchMap(() =>
        this.voyageService.loadVoyageAnomalies().pipe(
          map((anomalies) => fromVoyages.loadVoyageAnomaliesSuccess({ anomalies })),
          catchError((error) => of(fromVoyages.loadVoyageAnomaliesFailure({ error }))),
        ),
      ),
    );
  });

  loadVoyageDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        fromVoyages.loadVoyageDetails,
        fromVoyages.patchVoyageSuccess,
        fromVoyages.assignVoyageSuccess,
        fromVoyages.setVoyageTagsSuccess,
        fromVoyages.setVoyageVesselNameSuccess,
        fromVoyages.setVoyageReportRangeSuccess,
        fromVoyages.resetVoyageReportRangeSuccess,
        fromVoyages.patchPreferredVoyageDistanceSuccess,
        fromVoyages.unverifyVoyageSuccess,
      ),
      exhaustMap(
        ({ uuid }) =>
          uuid &&
          this.voyageService.loadVoyageDetails(uuid).pipe(
            map(({ data: [voyage] }) => fromVoyages.loadVoyageDetailsSuccess({ voyage })),
            catchError((error) => of(fromVoyages.loadVoyageDetailsFailure({ error }))),
          ),
      ),
    );
  });

  loadVoyageSummary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.setVoyageFilters),
      switchMap(({ filters }) => {
        const filter =
          filters?.status?.includes('all') || filters?.status?.includes('verified') ? `?voyage_status=all` : '';

        return this.voyageService.loadVoyageSummary(filter).pipe(
          map(({ data: voyageSummary }) => fromVoyages.loadVoyageSummarySuccess({ voyageSummary })),
          catchError((error) => of(fromVoyages.loadVoyageSummaryFailure({ error }))),
        );
      }),
    );
  });

  loadVoyageDetailsSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.loadVoyageDetailsSuccess),
      concatMap(
        ({
          voyage: {
            report: {
              ship: { imo },
            },
            voyage,
          },
        }) => [
          fromReports.loadShipReports({ imo, showAnomalies: true }),
          // fromVoyages.loadShipVoyages({ imo }),  // this loads Associate/ship voyages. Please don't remove
          fromShips.loadLinkFromImo({ imo, cpid: voyage?.idCharterer }),
        ],
      ),
    );
  });

  loadShipVoyages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.loadShipVoyages),
      exhaustMap(({ imo }) =>
        this.voyageService
          .loadShipVoyages(imo)
          .pipe(map(({ data: shipVoyages }) => fromVoyages.loadShipVoyagesSuccess({ shipVoyages }))),
      ),
      catchError((error) => of(fromVoyages.loadShipVoyagesFailure({ error }))),
    );
  });

  setVoyageFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.setVoyageFilters),
      switchMap(({ filters: voyageFilters }) => {
        localStorage.setItem('filters', JSON.stringify(voyageFilters));
        const currentFilters = { ...voyageFilters, page: (voyageFilters.page - 1) * voyageFilters.pageSize };
        const filters = typedKeys(currentFilters)
          .filter((key: keyof VoyageFilters) => !!currentFilters[key])
          .map((key: keyof VoyageFilters) => `${key}=${currentFilters[key]}`)
          .reduce((queries, currentFilter) => (queries ? queries + `&${currentFilter}` : queries + currentFilter), '')
          .replace('pageSize', 'limit')
          .replace('page', 'offset');
        return of(fromVoyages.loadVoyagesList({ filters }));
      }),
    );
  });

  createVoyage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.createVoyage),
      mergeMap(({ voyage: newVoyage }) =>
        this.voyageService.createVoyage(backendVoyage(newVoyage)).pipe(
          map(({ uuid }) => fromVoyages.createVoyageSuccess({ voyage: newVoyage, uuid })),
          catchError((error) => of(fromVoyages.createVoyageFailure({ error }))),
        ),
      ),
    );
  });

  createVoyageSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.createVoyageSuccess),
      concatLatestFrom(() => this.store.select(selectShipUuids)),
      mergeMap(([{ voyage, uuid }, shipUuids]) => {
        this._dialog.closeAll();
        const url = `voyages/analysis/${uuid}`;
        setTimeout(() => {
          this.router.navigate([url], {
            queryParams: { segment: 'overview' },
          });
        }, 2000);
        const shouldCreateShipLink = !shipUuids.some(({ imo }) => imo === voyage.imo);
        const { imo, idCharterer: chartererReference } = voyage;
        return shouldCreateShipLink
          ? of(fromShips.createLink({ imo, chartererReference }))
          : of(fromVoyages.voyageNoop());
      }),
    );
  });

  updateVoyage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.updateVoyage),
      mergeMap(({ voyage, uuid }) =>
        this.voyageService.updateVoyage(backendVoyage(voyage), uuid).pipe(
          map((returnedVoyage) => fromVoyages.updateVoyageSuccess({ voyage: returnedVoyage })),
          catchError((error) => of(fromVoyages.updateVoyageFailure({ error }))),
        ),
      ),
    );
  });

  updateVoyageSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.deleteVoyageSuccess),
      map(({ uuid }) => {
        if (this.router.url.includes('analysis')) {
          return fromVoyages.loadVoyageDetails({ uuid });
        } else {
          const filters = getUserDefinedFilters();
          return fromVoyages.setVoyageFilters({ filters });
        }
      }),
    );
  });

  patchPrefferedVoyageDistance$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.patchPreferredVoyageDistance),
      mergeMap(({ uuid, preferredDistance }) =>
        this.voyageService.patchPreferredVoyageDistance(uuid, preferredDistance).pipe(
          map((voyage) => fromVoyages.patchPreferredVoyageDistanceSuccess({ voyage, uuid, preferredDistance })),
          catchError((error) => of(fromVoyages.patchPreferredVoyageDistanceFailure({ error }))),
        ),
      ),
    );
  });

  patchVoyage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.patchVoyage),
      mergeMap(({ voyage: partialVoyage, uuid }) =>
        this.voyageService.patchVoyage(backendVoyage(partialVoyage), uuid).pipe(
          map((voyage) => fromVoyages.patchVoyageSuccess({ voyage, uuid })),
          catchError((error) => of(fromVoyages.patchVoyageFailure({ error }))),
        ),
      ),
    );
  });

  assignVoyage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.assignVoyage),
      mergeMap(({ assignedTo, uuids }) =>
        this.voyageService.assignVoyage(assignedTo, uuids).pipe(
          map(() => fromVoyages.assignVoyageSuccess({ uuid: uuids.split(',')[0] })),
          catchError((error) => of(fromVoyages.patchVoyageFailure({ error }))),
        ),
      ),
    );
  });

  setStartReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.setStartReport),
      mergeMap(({ reportID, uuid }) =>
        this.voyageService.setStartReport(reportID, uuid).pipe(
          map(() => fromVoyages.setVoyageReportRangeSuccess({ uuid })),
          catchError((error) => of(fromVoyages.patchVoyageFailure({ error }))),
        ),
      ),
    );
  });

  setEndReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.setEndReport),
      mergeMap(({ reportID, uuid }) =>
        this.voyageService.setEndReport(reportID, uuid).pipe(
          map(() => fromVoyages.setVoyageReportRangeSuccess({ uuid })),
          catchError((error) => of(fromVoyages.patchVoyageFailure({ error }))),
        ),
      ),
    );
  });

  resetVoyageReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.resetVoyageReportRange),
      mergeMap(({ reportType, uuid }) =>
        this.voyageService.resetVoyageReportRange(reportType, uuid).pipe(
          map((voyage) => fromVoyages.resetVoyageReportRangeSuccess({ voyage, uuid })),
          catchError((error) => of(fromVoyages.resetVoyageReportRangeFailure({ error }))),
        ),
      ),
    );
  });

  setVoyageTags$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.setVoyageTags),
      mergeMap(({ tags, uuid }) =>
        this.voyageService.setVoyageTags(tags, uuid).pipe(
          map(() => fromVoyages.setVoyageTagsSuccess({ uuid })),
          catchError((error) => of(fromVoyages.patchVoyageFailure({ error }))),
        ),
      ),
    );
  });

  setVoyageVesselName$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.setVoyageVesselName),
      mergeMap(({ vesselName, uuid }) =>
        this.voyageService.setVoyageVesselName(vesselName, uuid).pipe(
          map(() => fromVoyages.setVoyageVesselNameSuccess({ uuid })),
          catchError((error) => of(fromVoyages.patchVoyageFailure({ error }))),
        ),
      ),
    );
  });

  patchVoyageSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.patchVoyageSuccess),
      concatLatestFrom(() => this.store.select(selectShipUuids)),
      switchMap(([{ voyage }, shipUuids]) => {
        this._dialog.closeAll();
        const shouldCreateShipLink = !shipUuids.some(({ imo }) => imo === voyage.imo);
        const { imo, idCharterer: chartererReference } = voyage;
        const filters = getUserDefinedFilters();
        const isVoyageList = this.router.url.includes('active');

        return shouldCreateShipLink
          ? of(fromShips.createLink({ imo, chartererReference }))
          : isVoyageList
            ? of(fromVoyages.setVoyageFilters({ filters }))
            : of(fromVoyages.voyageNoop());
      }),
    );
  });

  assignVoyageSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.assignVoyageSuccess),
      switchMap(() => {
        this._dialog.closeAll();
        const filters = getUserDefinedFilters();
        const isVoyageList = this.router.url.includes('active');
        return isVoyageList ? of(fromVoyages.setVoyageFilters({ filters })) : of(fromVoyages.voyageNoop());
      }),
    );
  });

  loadVoyageComments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.loadVoyageComments, fromVoyages.unverifyVoyageSuccess),
      mergeMap(
        ({ uuid }) =>
          uuid &&
          this.voyageService.loadVoyageComments(uuid).pipe(
            map(({ data: comments }) => fromVoyages.loadVoyageCommentsSuccess({ comments })),
            catchError((error) => of(fromVoyages.loadVoyageCommentsFailure({ error }))),
          ),
      ),
    );
  });

  addVoyageComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.addVoyageComment),
      mergeMap(({ comment: newComment, uuid }) =>
        this.voyageService.addVoyageComment(newComment, uuid).pipe(
          map(({ body: { data: comment } }) => fromVoyages.addVoyageCommentSuccess({ comment })),
          catchError((error) => of(fromVoyages.addVoyageCommentFailure({ error }))),
        ),
      ),
    );
  });

  updateVoyageComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.updateVoyageComment),
      mergeMap(({ comment: newComment, uuid }) =>
        this.voyageService.updateVoyageComment(newComment, uuid).pipe(
          map(({ body: { data: comment } }) => fromVoyages.updateVoyageCommentSuccess({ comment })),
          catchError((error) => of(fromVoyages.updateVoyageCommentFailure({ error }))),
        ),
      ),
    );
  });

  deleteVoyageComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.deleteVoyageComment),
      mergeMap(({ commentId, uuid }) =>
        this.voyageService.deleteVoyageComment(commentId, uuid).pipe(
          map(() => fromVoyages.deleteVoyageCommentSuccess({ commentId })),
          catchError((error) => of(fromVoyages.deleteVoyageCommentFailure({ error }))),
        ),
      ),
    );
  });

  verifyVoyage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.verifyVoyage),
      mergeMap(({ uuid, remark }) => {
        const data = { verified: true, remark };
        return this.voyageService.setVerified(data, uuid).pipe(
          map(() => fromVoyages.verifyVoyageSuccess({ voyage: { uuid, remark } })),
          catchError((error) => of(fromVoyages.verifyVoyageFailure({ error }))),
        );
      }),
    );
  });

  unverfiyVoyage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.unverifyVoyage),
      mergeMap(({ uuid, remark }) => {
        const data = { verified: false, remark };
        return this.voyageService.setVerified(data, uuid).pipe(
          map(() => fromVoyages.unverifyVoyageSuccess({ uuid })),
          catchError((error) => of(fromVoyages.unverifyVoyageFailure({ error }))),
        );
      }),
    );
  });

  updateVoyageRemark$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.updateVoyageRemark),
      concatLatestFrom(() => this.store.select(selectVoyages)),
      mergeMap(([{ uuid, remark }, voyages]) => {
        const currentVoyage = voyages.find(({ uuid: voyageUuid }) => voyageUuid === uuid);
        const voyage = { ...currentVoyage, remark };
        return of(fromVoyages.updateVoyage({ voyage, uuid }));
      }),
    );
  });

  deleteVoyage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.deleteVoyage),
      mergeMap(({ uuid }) =>
        this.voyageService.deleteVoyage(uuid).pipe(
          map(() => fromVoyages.deleteVoyageSuccess({ uuid })),
          catchError((error) => of(fromVoyages.deleteVoyageFailure({ error }))),
        ),
      ),
    );
  });

  deleteMultipleVoyages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.deleteMultipleVoyages),
      mergeMap(({ uuids }) => from(uuids).pipe(concatMap((uuid) => of(fromVoyages.deleteVoyage({ uuid }))))),
    );
  });

  promptDeleteVoyage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromVoyages.promptDeleteVoyage),
      tap(({ voyage }) => {
        const { uuid, charterpartyDate, imo, idCharterer, chartererName, shipName } = voyage;
        this._dialog
          .open(ConfirmationDialogComponent, {
            disableClose: true,
            width: '600px',
            height: '400px',
            data: {
              title: 'Confirm delete voyage',
              message: `
            <p>Are you sure you want to delete the following voyage?</p>
            <p><b>Voyage UUID:</b> ${uuid}</p>
            <p><b>Ship:</b> ${shipName}, IMO ${imo}</p>
            <p><b>Charterer name:</b> ${chartererName}</p>
            <p><b>Charterer reference:</b> ${idCharterer}</p>
            <p><b>Charterparty date:</b> ${moment(charterpartyDate).format('DD. MMM YYYY')}</p>
          `,
              btnOkText: 'Delete voyage',
            },
          })
          .afterClosed()
          .subscribe((data: boolean) => {
            if (data) {
              return fromVoyages.deleteVoyage({ uuid });
            }
          });
      }),
    );
  });

  success$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          fromVoyages.updateVoyageSuccess,
          fromVoyages.createVoyageSuccess,
          fromVoyages.deleteVoyageSuccess,
          fromVoyages.patchVoyageSuccess,
          fromVoyages.assignVoyageSuccess,
          fromVoyages.setVoyageTagsSuccess,
          fromVoyages.setVoyageVesselNameSuccess,
          fromVoyages.setVoyageReportRangeSuccess,
          fromVoyages.resetVoyageReportRangeSuccess,
          fromVoyages.patchPreferredVoyageDistanceSuccess,
          fromVoyages.verifyVoyageSuccess,
          fromVoyages.unverifyVoyageSuccess,
        ),
        tap(({ type }) => this.toastr.success('The operation was successful!', type, { timeOut: 2000 })),
      );
    },
    { dispatch: false },
  );

  error$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          fromVoyages.updateVoyageFailure,
          fromVoyages.createVoyageFailure,
          fromVoyages.deleteVoyageFailure,
          fromVoyages.patchVoyageFailure,
          fromVoyages.resetVoyageReportRangeFailure,
          fromVoyages.patchPreferredVoyageDistanceFailure,
          fromVoyages.verifyVoyageFailure,
          fromVoyages.unverifyVoyageFailure,
        ),
        tap(({ type, error }) =>
          this.toastr.error(error && error.error ? error.error : 'Something went wrong', type, { timeOut: 2000 }),
        ),
      );
    },
    { dispatch: false },
  );

  authorizationError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromVoyages.loadVoyageDetailsFailure, fromVoyages.loadShipVoyagesFailure),
        tap(({ type, error }) => {
          if (error.status === 401 || error.status === 403) {
            this.toastr.error('Token Expired', type, { timeOut: 2000 });
          }
        }),
      );
    },
    { dispatch: false },
  );
}
